import React, { Fragment } from 'react';
import Helmet from 'react-helmet'

const Privacidad = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Aviso de privacidad ~ Antigua Finca San Cristobal</title>
            </Helmet>
            <div className="dark:bg-gray-800 md:p-10 p-5 lg:mt-10">
                <div className="max-w-7xl mx-auto lg:pt-20 lg:pb-20">
                    <h1 className="text-center text-tango-500 constantine text-5xl tracking-wide pb-5">
                        <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                            Aviso de privacidad
                        </span>
                    </h1>
                    <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500 text-justify dark:text-gray-300">
                            <p>
                                La confidencialidad y debida protección de la información personal obtenida La Cantera de Desarrollos Residenciales, S. de R.L. de C.V.,y subsidiarias se rige bajo los lineamientos establecidos en la Ley Federal de Protección de Datos Personales en Posesión de Particulares (la “Ley”), su reglamento y demás legislación aplicable.
                                Su información personal, de ser requerida, será utilizada para realizar las gestiones necesarias para que usted, una vez cubiertos los requisitos correspondientes, cuente con un crédito destinado a que pueda adquirir alguno de los inmuebles que comercializamos;  como para informarle sobre cambios en los mismos y/o evaluar la calidad del servicio que le brindamos; y, en su caso para la formalización de operaciones de compraventa.
                            </p>
                            <br />
                            <p>
                                Para lo anterior, requerimos obtener los siguientes datos personales: nombre completo, edad, estado civil, régimen matrimonial, CURP y/o RFC, lugar de nacimiento, domicilio, teléfono de casa, ocupación, empresa en la que trabaja, domicilio y teléfono de oficina, salario, número de seguridad social, datos generales de su cónyuge y dos referencias familiares; adjuntando la documentación que nos permita corroborar dichos datos.
                            </p>
                            <br />
                            <p>
                                De acuerdo con la Ley, usted tiene derecho de Acceder a sus datos personales, así como a Rectificarlos en caso de ser inexactos; instruirnos para
                                Cancelarlos u Oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado. En caso de solicitar el ejercicio
                                de alguno de los derechos antes indicados, es necesario que presente su petición en la misma oficina en la que le fue proporcionado el presente documento;
                                o bien, en nuestro <strong>Departamento de Datos Personales</strong>,; mediante un escrito que usted puede redactar libremente en el que nos indique su nombre y domicilio,
                                los datos a los que usted desea tener acceso o que sean modificados, y deberá acompañar copia de una identificación oficial.
                            </p>
                            <br />
                            <p>
                                Una vez presentada su solicitud, La Cantera de Desarrollos Residenciales, S. de R.L. de C.V tendrá un plazo máximo de 20 días hábiles para  analizar su petición y tomar las medidas correspondientes. Una vez de trascurrido dicho término, contaremos con 15 días hábiles para que le entreguemos por escrito la respuesta a su solicitud y, en su caso, las medidas llevadas a cabo de acuerdo a su solicitud.
                            </p>
                            <br />
                            <p>
                                Asimismo, le informamos que como Finalidades Primarias es necesario transferir sus datos personales a personas distintas a esta empresa, ya sean instituciones crediticias, tales como INFONAVIT,  FOVISSSTE y/o Sociedades Hipotecarias; y, en caso de formalizar una operación de compraventa, con notarios públicos, quienes se encuentran obligados al mismo tratamiento confidencial; en el entendido de que si usted manifiesta su oposición para que sus datos personales sean transferidos, nos veremos imposibilitados para llevar a cabo la gestión del crédito mencionado o, en su caso, la formalización de la operación de compraventa.
                            </p>
                            <br />
                            <p>
                                Cualquier modificación a este aviso de privacidad podrá consultarla en nuestra página de Internet (https://antiguafincasancristobal.com/).
                            </p>
                            <br />
                            <p>
                                <strong>
                                    Departamento de Datos Personales:
                                </strong>
                                Guillermo Xicotencatl No. 59 Int.46, San Cristóbal Tulcingo, Puebla. C.P. 72100
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Privacidad;