import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import HomeIcon from '@material-ui/icons/Home';
import KingBedIcon from '@material-ui/icons/KingBed';
import KitchenIcon from '@material-ui/icons/Kitchen';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
import BathtubIcon from '@material-ui/icons/Bathtub';
import DeckIcon from '@material-ui/icons/Deck';
import TvIcon from '@material-ui/icons/Tv';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import Carousel from '../components/Carousel/Carousel'
import { useHistory } from "react-router-dom";

export default function Prototipo(props) {
    let history = useHistory();

    function handleContactanos() {
        history.push("/Contact");
    }

    return (
        <Fragment>
            <Helmet>
                <title>{props.location.name} ~ Antigua Finca San Cristobal</title>
            </Helmet>
            <div className="dark:bg-gray-800">
                <div className="container mx-auto">
                    <div className="text-center pb-12 pt-12">
                        <h3 className="mt-2 text-3xl leading-8 tracking-tight sm:text-4xl constantine text-center pb-5">
                            <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                {props.location.name}
                            </span>
                        </h3>
                        <h1 className="text-center volk  font-light text-2xl uppercase">
                            <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                                {props.location.description}
                            </span>
                        </h1>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
                        <div className="col-span-2 mx-auto" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src="img/CasaEjemplo.jpg" alt={props.name} />
                        </div>
                        <div>
                            <div className="pt-12 p-5 lg:p-10">
                                <h3 className="mt-2 text-3xl leading-8 tracking-tight sm:text-4xl constantine text-center pb-5">
                                    <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                        <HomeIcon fontSize={'large'} />  Modelo de la casa
                                    </span>
                                </h3>
                                <h1 className="text-center volk  font-light text-2xl uppercase">
                                    <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                                        Conoce el diseño de esté prototipo
                                    </span>
                                </h1>
                                <div className="mt-10">
                                    <div className="mt-2 mb-6">
                                        <h3 className="mt-2 text-3xl leading-8 tracking-tight sm:text-4xl constantine text-center pb-5">
                                            <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                                Torre I - Tipo B
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="md:grid gap-4 md:text md:grid-cols-2">
                                        <div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <KingBedIcon className="text-tango-500 -mt-1" /><span className="p-2 mt-2 raleway">Recamaras: 2</span></p>
                                            </div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <KitchenIcon className="text-tango-500 -mt-1" /> <span className="p-2 mt-2 raleway">Cocina Sí</span></p>
                                            </div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <RoomServiceIcon className="text-tango-500 -mt-1" /> <span className="p-2 mt-2 raleway">Comedor Sí</span></p>
                                            </div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <LocalLaundryServiceIcon className="text-tango-500 -mt-1" /> <span className="p-2 mt-2 raleway">Área de lavado Sí</span></p>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <BathtubIcon className="text-tango-500 -mt-1" /><span className="p-2 mt-2 raleway">Baños: 2</span></p>
                                            </div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <DeckIcon className="text-tango-500 -mt-1" /> <span className="p-2 mt-2 raleway">Roof Garden No</span></p>
                                            </div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <TvIcon className="text-tango-500 -mt-1" /> <span className="p-2 mt-2 raleway">Sala Sí</span></p>
                                            </div>
                                            <div>
                                                <p className="noto p-2 rounded hover:bg-tango-500 hover:text-white cursor-pointer iconHover text-gray-700 dark:text-gray-300">
                                                    <DirectionsCarIcon className="text-tango-500 -mt-1" /> <span className="p-2 mt-2 raleway">Cochera 1</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-20 pb-10 dark:bg-gray-800">
                <Carousel />
            </div>
            <div className="bg-gray-50 dark:bg-gray-700 p-10">
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div data-aos="fade-up-right" data-aos-duration="1000">
                            <img src="img/Nivel.png" alt="Nivel" />
                        </div>
                        <div data-aos="fade-up-left" data-aos-duration="1000">
                            <img src="img/Nivel.png" alt="Nivel" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white dark:bg-gray-800">
                <div className="max-w-7xl mx-auto pb-20">
                    <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8" data-aos="fade-up" data-aos-duration="1000">
                        <h2 className="text-5xl text-sycamore constantine">
                            <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                ¡Estas a un paso de iniciar!
                            </span>
                        </h2>
                        <button onClick={handleContactanos} className="bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn text-white dark:text-brown mt-10 pt-3 pb-3 pr-12 px-12 constantine font-light uppercase hover:from-mule hover:via-corn hover:to-brown">Contáctanos</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}