import React, { Fragment, useState } from "react";
import Helmet from 'react-helmet'
import Input from '../components/TextArea'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';

const faqs = [
  {
    question: "¿El desarrollo cuenta con todos los servicios?",
    answer:
      "Así es, contamos con Agua potable, alcantarillado sanitario y pluvial, red eléctrica, alumbrado, redes de televisión por cable, telefonía y wifi y redes de cámaras de seguridad, todas las instalaciones son subterráneas.",
  },
  {
    question: "¿El terreno es propiedad o ejido?",
    answer:
      "Los terrenos son propiedad, bajo un régimen de propiedad y condominio con permisos autorizados por la Dirección de desarrollo urbano y la Dirección de Catastro del municipio de Cuautlancingo e inscrito en el Registro público de la propiedad y del comercio de Cholula, Estado de Puebla.",
  },
  {
    question: "¿Cuándo se entregan los lotes?",
    answer:
      "La primera etapa que consta de los primeros 49 lotes está lista para escrituración y entrega. La preventa de la segunda etapa se estima continuara hasta el primer trimestre del 2023 pero ya se pueden escriturar.",
  },
  {
    question: "¿Ustedes pueden construir mi casa?",
    answer:
      "Claro que sí, contamos con varios diseños para que escojas el que más te guste o si lo prefieres te podemos hacer uno personalizado diseñado.",
  },
  {
    question: "¿Hay cuota de mantenimiento?",
    answer:
      "Si, esta se comienza a pagar hasta que el lote este escriturado.",
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ContactoSchema = Yup.object().shape({
  Nombre: Yup.string().required('Por favor, indicanos tu nombre'),
  Apellidos: Yup.string().required('Por favor, indicanos tus apellidos'),
  CorreoElectronico: Yup.string().email('Inserta un correo electrónico valido').required('Por favor, inserta tu correo electrónico'),
  Celular: Yup.number().required('Por favor inserta tu número teléfonico'),
  Comentarios: Yup.string().required('Por favor, indicanos tus comentarios').max(500),
  Enteraste: Yup.string().required('Por favor, indicanos cómo te enteraste de nosotros')
});

const Contact = () => {
  const [loading, setLoading] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Contacto ~ Antigua Finca San Cristobal</title>
      </Helmet>
      <div className="dark:bg-gray-800 md:p-10 p-5 lg:mt-10">
        <div className="max-w-7xl mx-auto lg:pt-20 lg:pb-20" data-aos="fade-up" data-aos-duration="1000">
          <h3 className="mt-2 text-3xl leading-8 tracking-tight sm:text-4xl constantine text-center">
            <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
              ¿Listo para vivir cerca de la naturaleza?
            </span>
          </h3>
          <div className="max-w-xl mx-auto">
            <div className="p-5 lg:p-0">
              <div className="mt-6 prose prose-indigo prose-lg noto text-brown dark:text-gray-300 mx-auto text-center">
                <p>
                  Si lo que buscas es una mayor calidad de vida en un lugar donde puedes tener la tranquilidad que te da el contacto
                  con la naturaleza haciendo yoga por las mañanas, caminatas entre los árboles por las tardes, ejercicio en un gimnasio completamente equipado,
                  desintoxicar tu cuerpo en un vapor con las instalaciones que tú te mereces, que tu mascota pueda correr libre en un espacio diseñado
                  específicamente para ella y con la mayor seguridad que un residencial te puede otorgar, no esperes más, contáctanos y conoce tu futuro hogar.
                </p>
              </div>
            </div>
          </div>
          <div className="max-w-3xl mx-auto pt-10">
            <Formik
              initialValues={{
                Nombre: '',
                Apellidos: '',
                CorreoElectronico: '',
                Celular: '',
                Comentarios: '',
                Enteraste: '',
              }}
              validationSchema={ContactoSchema}
              onSubmit={values => {
                setLoading(true);
                axios.post('https://api.antiguafinca.com:4004/api/lead', {
                  Name: values.Nombre,
                  LastName: values.Apellidos,
                  Mail: values.CorreoElectronico,
                  Cel: values.Celular,
                  Comments: values.Comentarios,
                  Find: values.Enteraste,
                  Date: Date.now()
                })
                  .then(function (response) {
                    if (response.status === 200) {
                      values.Nombre = '';
                      values.Apellidos = '';
                      values.CorreoElectronico = '';
                      values.Celular = '';
                      values.Comentarios = '';
                      values.Enteraste = '';
                      setOpenAlert(true);
                      setLoading(false);
                    }
                    else {
                      console.log(response.status);
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="Nombre"
                          id="Nombre"
                          placeholder="Nombre(s)"
                          autoComplete="given-name"
                          className="block w-full shadow-sm sm:text-sm focus:ring-tango-500 focus:border-tango-500 border-gray-300 rounded-md"
                        />
                        {errors.Nombre && touched.Nombre ? (
                          <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Nombre}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="Apellidos"
                          placeholder="Apellidos"
                          id="Apellidos"
                          autoComplete="family-name"
                          className="block w-full shadow-sm sm:text-sm focus:ring-tango-500 focus:border-tango-500 border-gray-300 rounded-md"
                        />
                        {errors.Apellidos && touched.Apellidos ? (
                          <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Apellidos}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <div className="mt-1">
                        <Field
                          id="CorreoElectronico"
                          name="CorreoElectronico"
                          placeholder="Correo electrónico"
                          type="email"
                          autoComplete="email"
                          className="block w-full shadow-sm sm:text-sm focus:ring-tango-500 focus:border-tango-500 border-gray-300 rounded-md"
                        />
                        {errors.CorreoElectronico && touched.CorreoElectronico ? (
                          <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.CorreoElectronico}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <div className="flex justify-between">
                        <label htmlFor="Celular" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        </label>
                      </div>
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="Celular"
                          id="Celular"
                          placeholder="Celular"
                          autoComplete="tel"
                          aria-describedby="phone-description"
                          className="block w-full shadow-sm sm:text-sm focus:ring-tango-500 focus:border-tango-500 border-gray-300 rounded-md"
                        />
                        {errors.Celular && touched.Celular ? (
                          <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Celular}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 mt-4">
                      <div className="flex justify-between">
                        <label htmlFor="how-can-we-help" className="block text-sm font-medium text-gray-700 dark:text-gray-300">

                        </label>
                        <span id="Comentarios" className="text-sm text-brown dark:text-gray-300">
                          Max. 500 caracteres
                        </span>
                      </div>
                      <div className="mt-1">
                        <Field
                          as={Input}
                          id="Comentarios"
                          name="Comentarios"
                          placeholder="Dudas o comentarios"
                          aria-describedby="Comentarios"
                          rows={4}
                          className="block w-full shadow-sm sm:text-sm focus:ring-tango-500 focus:border-tango-500 border border-gray-300 rounded-md"
                        />
                        {errors.Comentarios && touched.Comentarios ? (
                          <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Comentarios}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <div className="mt-1">
                        <Field
                          type="text"
                          name="Enteraste"
                          placeholder="¿Cómo te enteraste de nosotros?"
                          id="Enteraste"
                          className="shadow-sm focus:ring-tango-500 focus:border-tango-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                        {errors.Enteraste && touched.Enteraste ? (
                          <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Enteraste}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      {
                        loading ?
                          <button className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-tango-600 dark:bg-tango-300 text-base font-medium text-white hover:bg-orange-antigua-700 dark:hover:bg-tango-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                            <CircularProgress size={20} className="mx-4 text-white" />
                            Enviando...
                          </button>
                          :
                          <button
                            type="submit"
                            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-tango-600 dark:bg-tango-500 hover:bg-tango-700 dark:hover:bg-tango-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tango-500"
                          >
                            Enviar
                          </button>
                      }
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 dark:bg-gray-600 pb-20">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto" data-aos="fade-up" data-aos-duration="800">
            <h3 className="mt-2 text-3xl leading-8 tracking-tight sm:text-4xl constantine text-center pb-10">
              <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                Preguntas frecuentes
              </span>
            </h3>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <Fragment>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-600 dark:text-gray-400 noto">{faq.question}</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-brown dark:text-gray-300 noto">{faq.answer}</p>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 pb-20">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto pb-20">
            <h3 className="mt-2 text-3xl leading-8 tracking-tight sm:text-4xl constantine text-center pb-5">
              <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                ¿Preguntas?
              </span>
            </h3>
            <h1 className="text-center volk  font-light text-2xl uppercase">
              <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                CONTÁCTANOS
              </span>
            </h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <iframe className="w-full h-full" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.876097532995!2d-98.20785624906573!3d19.11309075570771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc39c2a5812bf%3A0x9ec9228cc90961f8!2sAntigua%20Finca%20San%20Crist%C3%B3bal!5e0!3m2!1ses!2smx!4v1668706372891!5m2!1ses!2smx" allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="h-100">
              <div className="text-left">
                <div className="space-y-4">

                  <div className="group p-5 hover:bg-facebook-500 bg-facebook-50 rounded-lg shadow-lg cursor-pointer">
                    <a href="https://www.facebook.com/ResidencialAntiguaFinca" target="_blank" rel="noopener noreferrer">
                      <div className="pl-4">
                        <FacebookIcon className="mr-2 text-facebook-500 group-hover:text-white pb-2" style={{ fontSize: "40px" }} />
                      </div>
                      <p className="pl-4 noto text-gray-600 group-hover:text-white">
                        @AntiguaFinca
                      </p>
                    </a>
                  </div>


                  <div className="group p-5 hover:bg-youtube-500 bg-youtube-50 rounded-lg shadow-lg cursor-pointer">
                    <a href="https://www.youtube.com/channel/UCFLgi0bQ9GqynFS4ONFRPJQ" target="_blank" rel="noopener noreferrer">
                      <div className="pl-4">
                        <YouTubeIcon className="mr-2 text-youtube-500 group-hover:text-white pb-2" style={{ fontSize: "40px" }} />
                      </div>
                      <p className="pl-4 noto text-gray-600 group-hover:text-white">
                        @AntiguaFinca
                      </p>
                    </a>
                  </div>

                  <div className="group p-5 hover:bg-whatsapp-500 bg-whatsapp-50 rounded-lg shadow-lg cursor-pointer">
                    <a href="https://api.whatsapp.com/send?phone=2291614285&text=¡Hola!%20Me%20gustaría%20saber%20información%20acerca%20de%20Antigua%20Finca" target="_blank" rel="noopener noreferrer">
                      <div className="pl-4">
                        <WhatsAppIcon className="mr-2 text-whatsapp-500 group-hover:text-white pb-2" style={{ fontSize: "40px" }} />
                      </div>
                      <p className="pl-4 noto text-gray-600 group-hover:text-white">
                        22 91 61 42 85
                      </p>
                    </a>
                  </div>
                  <div className="group p-5 hover:bg-tango-500 bg-tango-50 rounded-lg shadow-lg cursor-pointer">
                    <a href="tel:5537425118" target="_blank" rel="noopener noreferrer">
                      <div className="pl-4">
                        <PhoneCallbackIcon className="mr-2 text-tango-500 group-hover:text-white pb-2" style={{ fontSize: "40px" }} />
                      </div>
                      <p className="pl-4 noto text-gray-600 group-hover:text-white">
                        (55) 37 42 51 18
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Tus datos se han enviado con exito.
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default Contact;