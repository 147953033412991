import React, { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline'
import {
    Link
} from "react-router-dom";
import { Switch } from '@headlessui/react'
import { useEffect, useRef } from 'react';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import FacebookIcon from '@material-ui/icons/Facebook';
import './Navbar.css'

// const prototipos = [
//     {
//         id: 1,
//         name: 'Prototipo 1',
//         description: 'Get a better understanding of where your traffic is coming from.',
//         img: 'img/Prototipo2.png',
//         costo: 'Desde $120,000.00',
//         href: '/prototipo',
//         icon: ChevronDoubleRightIcon,
//     },
//     {
//         id: 2,
//         name: 'Prototipo 2',
//         description: 'Speak directly to your customers in a more meaningful way.',
//         img: 'img/Prototipo2.png',
//         costo: 'Desde $120,000.00',
//         href: '/prototipo',
//         icon: ChevronDoubleRightIcon,
//     },
//     {
//         id: 3,
//         name: 'Prototipo 3',
//         description: "Your customers' data will be safe and secure.",
//         img: 'img/Prototipo2.png',
//         costo: 'Desde $120,000.00',
//         href: '/prototipo',
//         icon: ChevronDoubleRightIcon
//     },
//     {
//         id: 4,
//         name: 'Prototipo 4',
//         description: "Connect with third-party tools that you're already using.",
//         img: 'img/Prototipo2.png',
//         costo: 'Desde $120,000.00',
//         href: '/prototipo',
//         icon: ChevronDoubleRightIcon,
//     }
// ]

// const callsToAction = [
//     { name: 'Ver video', href: 'https://youtu.be/8ufV6O7DfQ4', icon: PlayIcon },
//     { name: 'Ver amenidades', href: '/proyecto', icon: CheckCircleIcon },
//     { name: 'Contactar', href: 'tel:2215984166', icon: PhoneIcon },
// ]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const [enabled, setEnabled] = useState(localStorage.theme)
    // const buttonref = useref();
    const buttonMobileRef = useRef();
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    }

    let navbarClasses = [''];

    if (scrolled) {
        navbarClasses.push('scrolled');
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        if (localStorage.theme === 'dark') {
            setEnabled(true);
        }
        else {
            setEnabled(false);
        }
    }, [enabled])

    if (enabled) {
        localStorage.theme = 'dark'
        document.documentElement.classList.add('dark')
    }
    else {
        localStorage.removeItem('theme')
        document.documentElement.classList.remove('dark')
    }

    return (
        <Fragment>
            <div className={navbarClasses.join(" ")}>
                <Popover className="bg-white raleway dark:bg-gray-800 shadow dark:shadow-dark-lg lg:top-0 w-full z-20 absolute">
                    {({ open }) => (
                        <Fragment>
                            <div className="bg-white-400 dark:bg-gray-700 z-50">
                                <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:justify-start lg:space-x-10 lg:px-8">
                                    <div className="flex justify-start lg:w-0 lg:flex-1">
                                        <Link to="/">
                                            <img src="img/LogoLightFinal.png" alt="Logo Antigua Finca" className="hidden lg:block dark:hidden" />
                                        </Link>
                                        <Link to="/">
                                            <img src="img/LogoLast.png" alt="Logo Antigua Finca" className="hidden lg:hidden -ml-10 dark:block" />
                                        </Link>
                                        <Link to="/">
                                            <img src="img/LogoLightFinal.png" alt="Logo Antigua Finca" className="block lg:hidden -ml-10 dark:hidden" />
                                        </Link>
                                    </div>
                                    <div className="-mr-2 -my-2 lg:hidden">
                                        <Popover.Button className="bg-tango rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tango-500">
                                            <span className="sr-only">Open menu</span>
                                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                    <Popover.Group as="nav" className="hidden lg:flex space-x-10">
                                        <Link to="/" className="text-base font-medium text-cyprus-500 dark:text-gray-200 dark:hover:text-tango-300 hover:text-tango-600">
                                            Antigua Finca
                                        </Link>
                                        <Link to="/MasterPlan" className="text-base font-medium text-cyprus-500 dark:text-gray-200 dark:hover:text-tango-300 hover:text-tango-600">
                                            Plan maestro
                                        </Link>
                                        <Link to="/Location" className="text-base font-medium text-cyprus-500 dark:text-gray-200 dark:hover:text-tango-300 hover:text-tango-600">
                                            Ubicación
                                        </Link>
                                        {/* <Popover>
                                            {({ open }) => (
                                                <Fragment>
                                                    <Popover.Button
                                                        className={classNames(
                                                            open ? 'text-tango-300 dark:text-gray-300' : 'text-cyprus-500 dark:text-gray-100',
                                                            'group rounded-md inline-flex items-center text-base font-medium hover:text-tango-600'
                                                        )}
                                                        ref={buttonRef}
                                                    >
                                                        <span>Prototipos</span>
                                                        <ChevronDownIcon
                                                            className={classNames(
                                                                open ? 'text-tango-300 dark:text-gray-300' : 'text-gray-300 dark:text-gray-100',
                                                                'ml-2 h-5 w-5 group-hover:text-tango-700'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </Popover.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        enter="transition ease-out duration-200"
                                                        enterFrom="opacity-0 -translate-y-1"
                                                        enterTo="opacity-100 translate-y-0"
                                                        leave="transition ease-in duration-150"
                                                        leaveFrom="opacity-100 translate-y-0"
                                                        leaveTo="opacity-0 -translate-y-1"
                                                    >
                                                        <Popover.Panel
                                                            static
                                                            className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white dark:bg-gray-800 dark:border-gray-600"
                                                        >
                                                            <div className="container mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                                                                {prototipos.map((item) => (
                                                                    <Link
                                                                        key={item.name}
                                                                        to={{
                                                                            pathname: item.href,
                                                                            id: item.id,
                                                                            name: item.name,
                                                                            description: item.description
                                                                        }}
                                                                        onClick={() => buttonRef.current.click()}
                                                                        className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-cyprus-500 dark:hover:bg-gray-600"
                                                                    >
                                                                        <div className="flex md:h-full lg:flex-col group">
                                                                            <div className="flex-shrink-0">
                                                                                <img src={item.img} alt="Prototipo" />
                                                                            </div>
                                                                            <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                                                                <div>
                                                                                    <p className="text-lg font-bold text-gray-800 group-hover:text-white  dark:text-gray-200">{item.name}</p>
                                                                                    <p className="mt-1 text-sm text-gray-800 group-hover:text-white dark:text-gray-300">{item.description}</p>
                                                                                    <p className="mt-1 text-base text-gray-800 group-hover:text-white dark:text-gray-300">{item.costo}</p>
                                                                                </div>
                                                                                <p className="mt-2 text-sm font-medium text-gray-800 group-hover:text-white dark:text-tango-200 lg:mt-4">
                                                                                    Ver prototipo <span aria-hidden="true">&rarr;</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                            <div className="bg-gray-50 dark:bg-gray-700">
                                                                <div className="container mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                                                                    {callsToAction.map((item) => (
                                                                        <div key={item.name} className="flow-root group">
                                                                            <a
                                                                                href={item.href}
                                                                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-800 group-hover:text-white hover:bg-sycamore-500 dark:text-gray-300 dark:hover:bg-gray-400"
                                                                            >
                                                                                <item.icon className="flex-shrink-0 h-6 w-6 text-gray-800 group-hover:text-white dark:text-gray-100" aria-hidden="true" />
                                                                                <span className="ml-3">{item.name}</span>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Popover.Panel>
                                                    </Transition>
                                                </ Fragment>
                                            )}
                                        </Popover> */}
                                        <Link to="/Contact" className="text-base font-medium text-cyprus-500 dark:text-gray-200 dark:hover:text-tango-300 hover:text-tango-600">
                                            Contacto
                                        </Link>
                                        <Switch
                                            checked={enabled}
                                            onChange={setEnabled}
                                            className={classNames(
                                                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                className={classNames(
                                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                )}
                                            >
                                                <span
                                                    className={classNames(
                                                        enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                        <path
                                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    className={classNames(
                                                        enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </Switch>
                                        <Brightness4Icon className="dark:text-gray-200 text-cyprus-500 ml-5 mr-5" />
                                    </Popover.Group>

                                    <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
                                        <div className="flex-column">
                                            <div>
                                                <a href="tel:5537425118" target="_blank" rel="noreferrer" className="text-base font-medium text-cyprus-500 dark:text-gray-200 dark:hover:text-tango-300 hover:text-tango-600 pr-4 border-r-2">
                                                    (55) 37 42 51 18
                                                </a>
                                                <a href="https://www.facebook.com/ResidencialAntiguaFinca" target="_blank" rel="noreferrer" className="text-base font-medium text-cyprus-500 dark:text-gray-200 dark:hover:text-tango-300 hover:text-tango-600">
                                                    <FacebookIcon className="rounded-lg -mt-1 ml-4" />
                                                </a>
                                            </div>
                                            <div className="absolute mt-5 w-full">
                                                <Link to="/Contact" className="w-full text-base font-bold bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn text-white dark:text-brown hover:from-mule hover:via-corn hover:to-brown pt-2 pb-2 px-6 rounded shadow-lg">
                                                    AGENDA UNA CITA
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Transition
                                show={open}
                                as={Fragment}
                                enter="duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel
                                    focus
                                    static
                                    className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
                                >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-gray-700 divide-y-2 divide-gray-50">
                                        <div className="pt-5 pb-6 px-5">
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <img
                                                        className="h-20"
                                                        src="img/AF_LogoLightShort.png"
                                                        alt="Logo Antigua Finca"
                                                    />
                                                </div>
                                                <div className="-mr-2">
                                                    <Popover.Button ref={buttonMobileRef} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                        <span className="sr-only">Close menu</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </Popover.Button>
                                                </div>
                                            </div>
                                            {/* <div className="mt-6">
                                                <nav className="grid gap-y-8">
                                                    {prototipos.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={{
                                                                pathname: item.href,
                                                                id: item.id,
                                                                name: item.name,
                                                                description: item.description
                                                            }}
                                                            onClick={() => buttonMobileRef.current.click()}
                                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-600"
                                                        >
                                                            <item.icon className="flex-shrink-0 h-6 w-6 text-tango-600 dark:text-tango-300" aria-hidden="true" />
                                                            <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-300">{item.name}</span>
                                                        </Link>
                                                    ))}
                                                </nav>
                                            </div> */}
                                        </div>
                                        <div className="py-6 px-5 space-y-6">
                                            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                                <Link to="/" className="text-base font-medium text-gray-500 dark:text-gray-300 hover:text-gray-900">
                                                    Antigua Finca
                                                </Link>
                                                <Link to="/MasterPlan" className="text-base font-medium text-gray-500 dark:text-gray-300 hover:text-gray-900">
                                                    Plan maestro
                                                </Link>
                                                <Link to="/Location" className="text-base font-medium text-gray-500 dark:text-gray-300 hover:text-gray-900">
                                                    Ubicación
                                                </Link>
                                                {/* <Link to="/Faq" className="text-base font-medium text-gray-500 dark:text-gray-300 hover:text-gray-900">
                                            FAQ
                                        </Link> */}
                                                <Link to="/Contact" className="text-base font-medium text-gray-500 dark:text-gray-300 hover:text-gray-900">
                                                    Contacto
                                                </Link>
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={classNames(
                                                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                    )}
                                                >
                                                    <span className="sr-only">Use setting</span>
                                                    <span
                                                        className={classNames(
                                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                                            'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                        )}
                                                    >
                                                        <span
                                                            className={classNames(
                                                                enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                            )}
                                                            aria-hidden="true"
                                                        >
                                                            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                <path
                                                                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <span
                                                            className={classNames(
                                                                enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                            )}
                                                            aria-hidden="true"
                                                        >
                                                            <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </Switch>
                                                <Brightness4Icon className="dark:text-gray-200 text-gray-400" />
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Fragment>
                    )}
                </Popover>
            </div>
        </Fragment>
    );
}