import React, { Fragment } from 'react'
// import Swiper core and required modules
import SwiperCore, { EffectFade, Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import './Carousel.css'

SwiperCore.use([EffectFade]);
SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation]);

export default function Carousel() {
    return (
        <Fragment>
            <div className="pb-20 dark:bg-gray-800">
                <Swiper
                    spaceBetween={0}
                    navigation={{ clickable: true }}
                    slidesPerView={2.5}
                    centeredSlides={true}
                    loop
                    pagination={{ clickable: true }}
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        // when window width is >= 480px
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        },
                        // when window width is >= 640px
                        1024: {
                            slidesPerView: 2.5,
                            spaceBetween: 0
                        }
                    }
                    }
                >
                     <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_Acceso_Caseta.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/1</span><br />
                                Acceso Caseta
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_Amenidades.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/2</span><br />
                                Vista Amenidades
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_GYM2.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/3</span><br />
                                GYM
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_GYM.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/4</span><br />
                                GYM
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_Trekking.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/5</span><br />
                                Pista Trekking
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_RoofGarden.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/6</span><br />
                                Roof Garden
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_Vapores.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/7</span><br />
                                Vapores
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/AF_Yoga.jpg"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/8</span><br />
                                Yoga
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_Carril_Nado.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/9</span><br />
                                Carril de nado
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_GYM.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/10</span><br />
                                GYM
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_GYM2.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/11</span><br />
                                GYM 2
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_Mucho_Mas.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/12</span><br />
                                Casa Club
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_Park_Friendly.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/13</span><br />
                                Pet Friendly
                            </p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_Park_Friendly2.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/14</span><br />
                                Park Pet Friendly
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_Roof_Garden.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/15</span><br />
                                Roof Garden
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="shadow-lg"
                            src="img/slides/Antigua_Finca_Amenidades_Trekking.png"
                            alt="Escudo Almenare"
                        />
                        <div className="mt-1 p-5 prose prose-indigo prose-lg noto text-gray-500 dark:text-gray-300 mx-auto">
                            <p>
                                <span className="font-bold">1/16</span><br />
                                Pista de Trekking
                            </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </Fragment>
    );
}
