import React, { lazy, Suspense } from 'react';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Prototipo from './components/Prototipo'
import ScrollToTop from './components/ScrollToTop'
import Index from './pages/Index'
import Location from './pages/Location'
import Contact from './pages/Contact'
import Privacidad from './pages/Privacidad';
import FloatingWhatsApp from 'react-floating-whatsapp'

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const MasterPlan = lazy(() => import('./pages/MasterPlan/MasterPlan'))

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route exact path="/MasterPlan">
          <Suspense fallback={<h1>Cargando...</h1>}>
            <MasterPlan />
          </Suspense>
        </Route>
        <Route exact path="/Location">
          <Location />
        </Route>
        <Route exact path="/Privacidad">
          <Privacidad />
        </Route>
        {/* <Route exact path="/Faq">
          <Faq />
        </Route> */}
        <Route exact path="/Contact">
          <Contact />
        </Route>
        <Route render={(props) => (
          <Prototipo {...props} />
        )} />
      </Switch>
      <Footer />
      <FloatingWhatsApp
        phoneNumber="5212291614285"
        accountName="Antigua Finca San Cristobal"
        statusMessage="En línea"
        chatMessage='¡Hola! 🤝 ¿Cómo te podemos ayudar?'
        placeholder="Descubre tu nuevo hogar..."
        allowClickAway
        notification
        notificationDelay={60000}
        className="z-10"
        notificationSound={false}
        avatar="favicon2.png"
        styles={{ marginRight: '85px' }}
      />
    </Router>
  );
}

export default App;

