import React, { Fragment } from 'react';
import Helmet from 'react-helmet'

const Location = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Ubicación ~ Antigua Finca San Cristobal</title>
            </Helmet>
            <div className="dark:bg-gray-800 md:p-10 p-5 lg:mt-10">
                <div className="max-w-7xl mx-auto lg:pt-20 lg:pb-20">
                    <h1 className="text-center text-tango-500 constantine text-5xl tracking-wide pb-5">
                        <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                            Ubicación
                        </span>
                    </h1>
                    <div className="p-5 lg:p-0">
                        <div className="mt-6 prose prose-indigo prose-lg noto text-brown dark:text-gray-300 mx-auto">
                            <p>
                                Pensado para llevar una vida con absoluta seguridad de ti y los tuyos, rodeados del más puro aire fresco y con todas las amenidades
                                necesarias para cuidar de tu salud, ANTIGUA FINCA SAN CRISTOBAL se distingue <span className="italic font-bold underline">por la mejora en la calidad de vida</span> que pocos residenciales pueden brindar.
                                Una zona de la ciudad que se ubica entre los principales centros de trabajo y con la cercanía a todo lo que necesitas y te mereces.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 pt-10 lg:pt-20">
                        <div className="flex p-5 lg:p-10">
                            <div className="self-center">
                                <div className="mt-6 prose prose-indigo prose-lg noto text-brown dark:text-gray-300 mx-auto">
                                    <ul className="leading-snug space-y-2">
                                        <li>
                                            <img src="img/customIcons/Icon-Volkswagen.png" alt="Icono Volkswagen" className="inline -mt-2 w-8 mr-2" />
                                            Volkswagen
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Marriot.png" alt="Icono Marriot" className="inline -mt-2 w-8 mr-2" />
                                            Hotel Marriot
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Liverpool.png" alt="Icono Liverpool" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Liverpool
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Carretera.png" alt="Icono Carretera" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Carretera la Covadonga
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Carretera.png" alt="Icono Carretera" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Autopista México-Puebla
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-CAPU.png" alt="Icono CAPU" className="inline -mt-2 w-8 mr-2 p-1" />
                                            CAPU
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Mercado.png" alt="Icono Mercado Hidalgo" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Plaza San Pedro
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Avenida.png" alt="Icono blvd. Norte" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Blvd. Norte
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Avenida.png" alt="Icono blvd. Hermanos Serdán" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Blvd. Hermanos Serdán
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Avenida.png" alt="Icono Heroés de Nacozari" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Héroes de Nacozari
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Mercado.png" alt="Icono Mercado Hidalgo" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Mercado Hidalgo
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Avenida.png" alt="Icono Avenida" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Av. 15 Norte
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Estadio.png" alt="Icono Estadio" className="inline -mt-2 w-9 mr-2 p-1" />
                                            Estadio Cuauhtémoc
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Hospital.png" alt="Icono Hospital" className="inline -mt-2 w-9 mr-2 p-1" />
                                            Hospital Rafael Moreno Valle
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Mercado.png" alt="Icono Central de Abastos" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Central de Abastos
                                        </li>
                                        <li>
                                            <img src="img/customIcons/Icon-Carretera.png" alt="Icono Carretera" className="inline -mt-2 w-8 mr-2 p-1" />
                                            Vía corta a Santa Ana
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 col-span-end p-5 lg:p-0" data-aos="fade-up-left" data-aos-duration="1000">
                            <div className="shadow-lg">
                                <img src="img/AF_Mapa5.png" className="block dark:hidden" alt="Mapa" />
                                <img src="img/AF_Mapa5Dark.png" className="hidden dark:block" alt="Mapa" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <iframe title='Map'className='w-full h-80' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.876097532995!2d-98.20785624906573!3d19.11309075570771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc39c2a5812bf%3A0x9ec9228cc90961f8!2sAntigua%20Finca%20San%20Crist%C3%B3bal!5e0!3m2!1ses!2smx!4v1668706372891!5m2!1ses!2smx" allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Fragment>
    );
};

export default Location;