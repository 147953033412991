import Helmet from 'react-helmet'
import MainCarousel from '../components/MainCarousel'
import { useHistory } from "react-router-dom";
import React, { Fragment } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

export default function Index() {
    let history = useHistory();

    function handleContactanos() {
        history.push("/Contact");
    }

    return (
        <Fragment>
            <Helmet>
                <title>Inicio ~ Antigua Finca San Cristobal</title>
                <meta
                    name="description"
                    content="Vive Antigua Finca San Cristobal."
                />
            </Helmet>
            <div className="relative">
                <MainCarousel className="w-full h-auto" />
                <div className="bg-gray-50 dark:bg-gray-700">
                    <img src="img/Back1.png" alt="Fondo de hojas" className="absolute w-full" style={{ zIndex: 0 }} />
                    <div className="max-w-7xl mx-auto pb-10 lg:pb-20">
                        <div className="p-5 pt-10 pb-10 lg:p-10 lg:pt-20 lg:pb-20">
                            <h1 className="text-center text-tango-500 constantine text-5xl" data-aos="fade-down" data-aos-duration="2000">
                                <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                    ¿Por qué Antigua Finca?
                                </span>
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="p-4 lg:p-0">
                                <iframe data-aos="fade-up-right" data-aos-duration="1000" className="rounded-lg lg:rounded-none" width="100%" height="350" src="https://www.youtube.com/embed/ficPrXwMbV4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="flex p-5 lg:p-10">
                                <div className="self-center" data-aos="fade-up-left" data-aos-duration="1000">
                                    <h1 className="text-center volk  font-light text-2xl uppercase">
                                        <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                                            ¿Cómo nació el proyecto?
                                        </span>
                                    </h1>
                                    <div className="mt-2 lg:mt-6 prose prose-indigo prose-lg noto text-brown dark:text-gray-300 mx-auto text-center">
                                        <p>
                                            Después de más de <span className="font-bold">20 años</span> en la construcción de desarrollos residenciales nos propusimos un desafío, en el siguiente conjunto
                                            debíamos entregar a nuestros clientes algo <span className="font-bold">más que simplemente una casa o un terreno</span>, después de muchas propuestas todos coincidimos que la salud es
                                            el bien más preciado, pero ¿cómo compaginar salud con bienes inmuebles? y encontramos que la naturaleza proporciona&nbsp;
                                            <span className="italic">la tranquilidad, alivia cualquier estrés y eso garantiza <span className="font-bold"> una mejor salud</span>...</span>
                                        </p>
                                        <br />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dark:bg-gray-800 ">
                    <div className="max-w-7xl mx-auto pb-10 lg:pb-20 pt-5 lg:pt-20">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="flex p-5 lg:p-10" data-aos="fade-up-right" data-aos-duration="1000">
                                <div className="self-center">
                                    <h1 className="text-center font-light text-brown dark:text-gray-100 volk text-2xl uppercase">
                                        <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                                            Residencial en el bosque
                                        </span>
                                    </h1>
                                    <div className="mt-6 prose prose-indigo prose-lg noto text-brown dark:text-gray-300 mx-auto text-center">
                                        <p>
                                            ... de ahí <span className="font-bold">nació la iniciativa</span> de un residencial rodeado de árboles,
                                            pero también muy cercano a la ciudad, la búsqueda duró varios años ya
                                            que los terrenos que cumplían lo primero carecían de lo segundo y viceversa, <span className="font-bold">la iniciativa se volvió necesidad y continuó hasta que encontramos
                                                lo que ahora es ANTIGUA FINCA SAN CRISTOBAL.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 lg:p-0">
                                {/* <iframe data-aos="fade-up-left" data-aos-duration="1000" width="100%" className="rounded-lg lg:rounded-none" height="350" src="https://www.youtube.com/embed/sW_tiO_1fM4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                <img src="img/AF_Naturaleza-2.jpg" alt="Casas en el bosque" className='mx-auto w-full' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-700">
                    <div className="max-w-7xl mx-auto pb-20">
                        <div className="p-5 lg:p-10 pt-10 lg:pt-20" data-aos="fade-up" data-aos-duration="1000">
                            <h1 className="text-center text-tango-500 constantine text-5xl tracking-wide pb-5">
                                <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                    Ubicación
                                </span>
                            </h1>
                            <h1 className="text-center font-light text-brown dark:text-gray-100 volk text-2xl">
                                <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                                    CERCA DE LA NATURALEZA Y LA CIUDAD
                                </span>
                            </h1>
                        </div>
                        <div className="max-w-2xl mx-auto" data-aos="fade-up" data-aos-duration="1000">
                            <div className="text-center p-5 lg:p-0">
                                <div className="mt-6 prose prose-indigo prose-lg noto text-brown dark:text-gray-300 mx-auto">
                                    <p className="italic">
                                        . . .  en la orilla del pastizal, se llega a la sombra que proyectan los árboles con los que limita y se siente inmediatamente un
                                        cambio de temperatura y de humedad, lo cual es aún más contrastante si el día es soleado, y que se agradece después de la caminata, pues el frescor resulta muy agradable.
                                        <br /><br />
                                        En estos momentos el bosque –porque estamos en un bosque dentro de la ciudad–, se adueña de los sentidos y se hace omnipresente. La ciudad se siente lejana y la abstracción que la naturaleza ejerce sobre uno es tan acogedora,
                                        que solo es rota por el ruido súbito de algún pequeño mamífero o reptil que se adivina alejándose entre la hojarasca . . .
                                        <br /><br />
                                        Biólogo Victor Gutiérrez Pacheco
                                        <br /><br />
                                        Lo anterior es un extracto de este&nbsp;
                                        <a className="underline" href="https://www.ladobe.com.mx/2020/07/barranca-el-conde-un-paraiso-urbano-de-puebla/" target="_blank" rel="noreferrer" >
                                            artículo
                                            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                                            </svg>
                                        </a>, del cual ANTIGUA FINCA SAN CRISTOBAL es parte
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 pt-10 lg:pt-20" data-aos="fade-up" data-aos-duration="1000">
                            <div className="col-span-3 col-span-end p-5 lg:p-0">
                                <div className="shadow-lg lg:p-5">
                                    <img src="img/AF_Mapa5.png" className="block dark:hidden" alt="Mapa" />
                                    <img src="img/AF_Mapa5Dark.png" className="hidden dark:block" alt="Mapa" />
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="self-center">
                                    <div className="mt-6 prose prose-indigo prose-lg noto text-brown dark:text-gray-300 mx-auto">
                                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-10">
                                            <div>
                                                <ul className="leading-snug space-y-4">
                                                    <li>
                                                        <img src="img/customIcons/Icon-Volkswagen.png" alt="Icono Volkswagen" className="inline -mt-2 w-8 mr-2" />
                                                        Volkswagen
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Marriot.png" alt="Icono Marriot" className="inline -mt-2 w-8 mr-2" />
                                                        Hotel Marriot
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Liverpool.png" alt="Icono Liverpool" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Liverpool
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Carretera.png" alt="Icono Carretera" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Carretera la Covadonga
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Carretera.png" alt="Icono Carretera" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Autopista México-Puebla
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Mercado.png" alt="Icono Mercado Hidalgo" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Mercado Hidalgo
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <ul className="leading-snug space-y-4">
                                                    <li>
                                                        <img src="img/customIcons/Icon-CAPU.png" alt="Icono CAPU" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        CAPU
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Avenida.png" alt="Icono blvd. Norte" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Blvd. Norte
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Mercado.png" alt="Icono blvd. Norte" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Plaza San Pedro
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Avenida.png" alt="Icono blvd. Hermanos Serdán" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Blvd. Hermanos Serdán
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Avenida.png" alt="Icono Heroés de Nacozari" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Héroes de Nacozari
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <ul className="leading-snug space-y-4">
                                                    <li>
                                                        <img src="img/customIcons/Icon-Avenida.png" alt="Icono Avenida" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Av. 15 Norte
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Estadio.png" alt="Icono Estadio" className="inline -mt-2 w-9 mr-2 p-1" />
                                                        Estadio Cuauhtémoc
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Hospital.png" alt="Icono Hospital" className="inline -mt-2 w-9 mr-2 p-1" />
                                                        Hospital Rafael Moreno Valle
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Mercado.png" alt="Icono Central de Abastos" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Central de Abastos
                                                    </li>
                                                    <li>
                                                        <img src="img/customIcons/Icon-Carretera.png" alt="Icono Carretera" className="inline -mt-2 w-8 mr-2 p-1" />
                                                        Vía corta a Santa Ana
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white dark:bg-gray-800">
                    <div className="max-w-7xl mx-auto pb-5 lg:pb-10">
                        <div className="p-5 lg:p-10 pt-10 lg:pt-20" data-aos="fade-up" data-aos-duration="1000">
                            <h1 className="text-center text-tango-500 constantine text-5xl pb-5 tracking-wide">
                                <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                    TOUR 360°
                                </span>
                            </h1>
                            <h1 className="text-center font-light text-brown volk text-2xl dark:text-gray-100">
                                <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                                    EL CONFORT QUE TENEMOS PARA TI
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
                <iframe title="vista" className="w-full" height='680' src='https://roundme.com/embed/766073/2410135' frameBorder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                {/* <div className="bg-gray-50 dark:bg-gray-700">
                    <img src="img/Back1.png" alt="Fondo de hojas" className="absolute w-full" style={{ zIndex: 1 }} />
                    <div className="max-w-5xl mx-auto pb-10 lg:pb-20">
                        <div className="p-5 lg:p-10 pt-10 lg:pt-20" data-aos="fade-up" data-aos-duration="1000">
                            <h1 className="text-center text-tango-500 constantine text-5xl pb-5">
                                <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                    Prototipos
                                </span>
                            </h1>
                            <h1 className="text-center font-light text-brown dark:text-gray-100 volk text-2xl">
                                <span className="block bg-gradient-to-r from-elf via-elf to-cyprus dark:from-elf dark:via-elf dark:to-white bg-clip-text text-transparent uppercase">
                                    DISEÑADOS PARA ASEGURAR TU COMODIDAD
                                </span>
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-10 lg:pt-20">
                            <div className="p-5 lg:p-0" data-aos="fade-up-right" data-aos-duration="1000">
                                <div className="z-0">
                                    <img src="img/Prototipo1.jpg" alt="Prototipo 1" className="w-full mx-auto" />
                                </div>
                                <div className="relative z-50 p-4 w-3/4 md:-mt-8 lg:-mx-6 bg-brown-500 dark:bg-corn">
                                    <div className="flex justify-between">
                                        <p className="text-white constantine">
                                            Tipo 1 <br />
                                            $2,000,000.00
                                        </p>
                                        <button className="border pt-1 pb-1 pr-4 px-4 constantine font-light text-white constantine uppercase hover:bg-white hover:border-corn hover:text-corn">Ver más</button>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 lg:p-0" data-aos="fade-up-left" data-aos-duration="1000">
                                <div className="mt-0 lg:mt-36">
                                    <div className="z-0">
                                        <img src="img/Prototipo1.jpg" alt="Prototipo 1" className="w-full mx-auto" />
                                    </div>
                                    <div className="relative z-10 p-4 float-right lg:-mr-6 w-3/4 md:-mt-8 right-0 bg-brown-500 dark:bg-corn">
                                        <div className="flex justify-between">
                                            <p className="text-white constantine">
                                                Tipo 2 <br />
                                                $2,000,000.00
                                            </p>
                                            <button className="border pt-1 pb-1 pr-4 px-4 constantine font-light text-white constantine uppercase hover:bg-white hover:border-corn hover:text-corn">Ver más</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 lg:p-0" data-aos="fade-up-right" data-aos-duration="1000">
                                <div className="z-0">
                                    <img src="img/Prototipo1.jpg" alt="Prototipo 1" className="w-full mx-auto" />
                                </div>
                                <div className="relative z-50 p-4 w-3/4 md:-mt-8 lg:-mx-6 bg-brown-500 dark:bg-corn">
                                    <div className="flex justify-between">
                                        <p className="text-white constantine">
                                            Tipo 3 <br />
                                            $2,000,000.00
                                        </p>
                                        <button className="border pt-1 pb-1 pr-4 px-4 constantine font-light text-white uppercase hover:bg-white hover:border-corn hover:text-corn">Ver más</button>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 lg:p-0" data-aos="fade-up-left" data-aos-duration="1000">
                                <div className="mt-0 lg:mt-36">
                                    <div className="z-0">
                                        <img src="img/Prototipo1.jpg" alt="Prototipo 1" className="w-full mx-auto" />
                                    </div>
                                    <div className="relative z-10 p-4 float-right lg:-mr-6 w-3/4 md:-mt-8 right-0 bg-brown-500 dark:bg-corn">
                                        <div className="flex justify-between">
                                            <p className="text-white constantine">
                                                Tipo 4 <br />
                                                $2,000,000.00
                                            </p>
                                            <button className="border pt-1 pb-1 pr-4 px-4 constantine font-light text-white uppercase hover:bg-white hover:border-corn hover:text-corn">Ver más</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="bg-white dark:bg-gray-800">
                    <div className="max-w-7xl mx-auto pb-20">
                        <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8" data-aos="fade-up" data-aos-duration="1000">
                            <h2 className="text-5xl text-sycamore constantine">
                                <span className="block bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn bg-clip-text text-transparent">
                                    ¡Estas a un paso de iniciar!
                                </span>
                            </h2>
                            <button onClick={handleContactanos} className="bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn text-white dark:text-brown mt-10 pt-3 pb-3 pr-12 px-12 constantine font-light uppercase hover:from-mule hover:via-corn hover:to-brown">Contáctanos</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}