/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const navigation = {
    // prototipos: [
    //     {
    //         id: 1,
    //         name: 'Prototipo 1',
    //         description: 'Get a better understanding of where your traffic is coming from.',
    //         img: 'img/Prototipo2.png',
    //         costo: 'Desde $120,000.00',
    //         href: '/prototipo'
    //     },
    //     {
    //         id: 2,
    //         name: 'Prototipo 2',
    //         description: 'Speak directly to your customers in a more meaningful way.',
    //         img: 'img/Prototipo2.png',
    //         costo: 'Desde $120,000.00',
    //         href: '/prototipo',
    //     },
    //     {
    //         id: 3,
    //         name: 'Prototipo 3',
    //         description: "Your customers' data will be safe and secure.",
    //         img: 'img/Prototipo2.png',
    //         costo: 'Desde $120,000.00',
    //         href: '/prototipo',
    //     },
    //     {
    //         id: 4,
    //         name: 'Prototipo 4',
    //         description: "Connect with third-party tools that you're already using.",
    //         img: 'img/Prototipo2.png',
    //         costo: 'Desde $120,000.00',
    //         href: '/prototipo',
    //     }
    // ],
    navegacion: [
        { name: 'Antigua Finca', href: '/' },
        { name: 'Plan maestro', href: '/MasterPlan' },
        { name: 'Ubicación', href: '/Location' },
        { name: 'Contacto', href: '/Contact' },
        { name: 'Aviso de privacidad', href: '/Privacidad' },
    ],
    contacto: [
        { name: 'Facebook', href: 'https://www.facebook.com/Antigua-Finca-San-Cristobal-111939360592627' },
        { name: 'YouTube', href: 'https://www.youtube.com/channel/UCFLgi0bQ9GqynFS4ONFRPJQ' },
        { name: 'WhatsApp', href: 'https://wa.me/2291614285?text=Me%20interesa%20Antigua%20Finca' },
        { name: 'Llamar', href: 'tel:2215984166' },
    ],
    inmobiliarias: [
        { name: 'Documentos', href: '#' },
        { name: 'Contacto', href: '#' },
        { name: 'Sección para colaboradores', href: '#' },
    ],
    social: [
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/Antigua-Finca-San-Cristobal-111939360592627',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'YouTube',
            href: 'https://www.youtube.com/channel/UCFLgi0bQ9GqynFS4ONFRPJQ',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}

export default function Footer() {
    return (
        <footer className="bg-cyprus-500 dark:bg-gray-700 border-t dark:border-t dark:border-gray-800" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="container mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="md:grid md:grid-cols-3 md:gap-8">
                    <div className="space-y-8 lg:col-span-1">
                        <img
                            className="w-1/4 lg:w-1/2 h-auto mx-auto dark:hidden"
                            src="img/AF_LogoLightShort.png"
                            alt="Logo footer"
                        />
                        <img
                            className="w-1/4 lg:w-1/2 h-auto mx-auto hidden dark:block"
                            src="img/AF_LogoLightShort.png"
                            alt="Logo footer"
                        />
                        <p className="text-gray-300 dark:text-gray-400 text-base noto text-center">
                            "Disfruta el silencio y la paz que solo la naturaleza te puede brindar"
                        </p>
                        <div className="flex space-x-6">
                            {navigation.social.map((item) => (
                                <a href={item.href} className="text-gray-400 dark:text-gray-200 hover:text-gray-400" target="_blank" rel="noreferrer">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                </a>
                            ))}
                        </div>
                    </div>
                    {/* <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2"> */}
                    <div className="md:grid md:grid-cols-2 md:gap-8">
                        {/* <div className="mt-12 md:mt-0">
                            <h3 className="text-sm font-semibold text-gray-300 dark:text-gray-200 tracking-wider uppercase constantine">Prototipos</h3>
                            <ul className="mt-4 space-y-4">
                                {navigation.prototipos.map((item) => (
                                    <li key={item.name}>
                                        <Link to={{
                                            pathname: item.href,
                                            id: item.id,
                                            name: item.name,
                                            description: item.description
                                        }} className="text-base text-gray-400 dark:text-gray-400 hover:text-tango-500 noto">
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                        <div className="mt-12 md:mt-0">
                            <h3 className="text-sm font-semibold text-gray-300 dark:text-gray-200 tracking-wider uppercase constantine">Navegación</h3>
                            <ul className="mt-4 space-y-4">
                                {navigation.navegacion.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base text-gray-400 dark:text-gray-400 hover:text-tango-500 noto">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="md:grid md:grid-cols-2 md:gap-8">
                        <div className="mt-12 md:mt-0">
                            <h3 className="text-sm font-semibold text-gray-300 dark:text-gray-200 tracking-wider uppercase constantine">Contacto directo</h3>
                            <ul className="mt-4 space-y-4">
                                {navigation.contacto.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base text-gray-400 dark:text-gray-400 hover:text-tango-500 noto">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* <div className="mt-12 md:mt-0">
                            <h3 className="text-sm font-semibold text-gray-300 dark:text-gray-200 tracking-wider uppercase constantine">Inmobiliarias</h3>
                            <ul className="mt-4 space-y-4">
                                {navigation.inmobiliarias.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base text-gray-400 dark:text-gray-400 hover:text-tango-500 noto">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                    </div>
                    {/* </div> */}
                </div>
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base text-gray-300 dark:text-gray-200 xl:text-center noto">&copy; {new Date().getFullYear()} Antigua Finca San Cristobal | Todos los derechos reservados. | <a href="/Privacidad" className="text-corn-500 dark:text-corn-400">Aviso de Privacidad</a></p>
                    <p className="text-base text-gray-300 dark:text-gray-200 xl:text-center constantine">Desarrollado con <span role="img" aria-label="Heart">💙</span> por&nbsp;
                        <a href="http://asymmetrics.com.mx" target="_blank" rel="noopener noreferrer" className="text-corn-500 dark:text-corn-400">
                            Asymmetrics
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    )
}