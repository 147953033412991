import React, { Fragment } from 'react'
// import Swiper core and required modules
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

var items = [
    {
        name: "Aprovecha septiembre y octubre...",
        img: "img/slides/AF_Acceso_Caseta.jpg",
        to: "/Contact",
        textButton: "Contácto"
    },
    {
        name: "Aparta con el 25% y llévate...",
        img: "img/cambios/AF_Apergolado.jpg",
        to: "/MasterPlan",
        textButton: "Ver amenidades"
    },
    {
        name: "$100,000 de descuento...",
        img: "img/cambios/AF_Anochecer.jpg",
        to: "/MasterPlan",
        textButton: "Ver plan maestro"
    },
    {
        name: "Por cada lote adquirido",
        img: "img/cambios/AF_EncinoVialidad.jpg",
        to: "/Location",
        textButton: "Ver ubicación"
    }
]

export default function MainCarousel() {

    return (
        <Fragment>
            <div className="dark:bg-gray-800 bg-gray-50">
                <Carousel
                    animation="fade"
                    fullHeightHover={true}
                    NextIcon={<ArrowForwardIcon />}
                    PrevIcon={<ArrowBackIcon />}
                    autoPlay={true}
                    IndicatorIcon={""}
                    indicatorContainerProps={{
                        style: {
                            display: 'none'
                        }
                    }}
                    className="w-full h-auto"
                >
                    {
                        items.map((item, i) => <Item key={i} item={item} />)
                    }
                </Carousel>
            </div>
        </Fragment>
    );
}

function Item(props) {
    return (
        <Paper>
            <div className="absolute text-left lg:mx-20 w-full h-screen">
                <div className="flex flex-col h-screen w-full">
                    <div className="self-start h-1/3">
                    </div>
                    <div className="self-center">
                        <div className="max-w-sm lg:container mx-auto p-5 mb-5 lg:mb-10">
                            <h1 className="bg-black bg-opacity-50 p-5 mb-10">
                                <span className="mt-1 block text-2xl lg:text-2xl tracking-wide xl:text-4xl" data-aos="fade-down" data-aos-duration="3000">
                                    <span className="block text-white text-left text-transparent marcellus uppercase">
                                        {/* <i>{props.item.name}</i> */}
                                    </span>
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <img src={props.item.img} alt="Carril de nado" className="mx-auto w-full h-auto lg:h-screen mt-20" />
            <div className="absolute -mt-16 w-full hidden md:block">
                <div className="max-w-7xl mx-auto">
                    <div className="text-right">
                        <a href="files/Brochure_AFSC.pdf" target="_blank" className="uppercase text-lg lg:text-2xl font-semibold bg-gradient-to-r from-brown via-corn to-mule dark:from-corn dark:via-white dark:to-corn text-white dark:text-brown hover:from-mule hover:via-corn hover:to-brown pt-2 pb-2 px-6 rounded shadow-lg">
                            DESCARGA EL BROCHURE
                        </a>
                    </div>
                </div>
            </div>
        </Paper>
    )
}
